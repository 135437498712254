/* Root HTML */

:root {
    --clr-primary-400: 263 55% 52%;
    --clr-secondary-400: 217 19% 35%;
    --clr-secondary-500: 219 29% 14%;
    --clr-neutral-100: 0 0% 100%;
    --clr-neutral-200: 210 46% 95%;
    --clr-neutral-300: 0 0% 81%;
    
    --fw-400: 500;
    --fw-700: 600;
    
    --fs-300: 0.6875rem;
    --fs-400: 0.8125rem;
    --fs-500: 1.25rem;
    
    --primary-color: #000000;
    --secondary-color: #ffffff;
    --tertiary-color: #C4A975;
    --comp-color:#ebebeb;

    box-sizing: border-box;
    font-family: "Roboto";
    }
    
    /* Box sizing rules */
    *,
    *::before,
    *::after {
    box-sizing: border-box;
    }
    
    /* Remove default margin */
    body,
    h1,
    h2,
    h3,
    h4,
    p,
    figure,
    blockquote,
    dl,
    dd {
    margin: 0;
    }
    
    /* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
    ul[role="list"],
    ol[role="list"] {
        list-style: none;
    }
    
    li {
        list-style: none;
    }
    
    /* Set core root defaults */
    html:focus-within {
    scroll-behavior: smooth;
    }
    
    /* Set core body defaults */
    body {
    min-height: 100vh;
    text-rendering: optimizeSpeed;
    line-height: 1.75;
    }
    
    /* A elements that don't have a class get default styles */
    a:not([class]) {
    text-decoration-skip-ink: auto;
    }
    
    a {
        text-decoration: none;
    }
    
    /* Make images easier to work with */
    img,
    picture {
    max-width: 100%;
    display: block;
    }
    
    /* Inherit fonts for inputs and buttons */
    input,
    button,
    textarea,
    select {
    font: inherit;
    }
    
    /* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
    @media (prefers-reduced-motion: reduce) {
    html:focus-within {
        scroll-behavior: auto;
    }
    
    *,
    *::before,
    *::after {
        animation-duration: 0.01ms !important;
        animation-iteration-count: 1 !important;
        transition-duration: 0.01ms !important;
        scroll-behavior: auto !important;
    }
    }
    
    body {
    display: grid;
    place-content: center;
    min-height: 100vh;
    
    font-family: var(--ff-primary);
    font-weight: var(--fw-400);
    
    background-color: var(--secondary-color);
    }
    
    h1,
    h2,
    h3 {
    font-weight: var(--fw-700);
    }
    
    main {
        width: 100vw;
    }
    
    /* utilities */
    .flex {
    display: flex;
    gap: var(--gap, 1rem);
    }
    
    .flow > *:where(:not(:first-child)) {
    margin-top: var(--flow-spacer, 1em);
    }
    
    .bg-primary-400 {
    background: hsl(var(--clr-primary-400));
    }
    .bg-secondary-400 {
    background: hsl(var(--clr-secondary-400));
    }
    .bg-secondary-500 {
    background: hsl(var(--clr-secondary-500));
    }
    
    .bg-neutral-100 {
    background: hsl(var(--clr-neutral-100));
    }
    
    .text-neutral-100 {
    color: hsl(var(--clr-neutral-100));
    }
    .text-secondary-400 {
    color: hsl(var(--clr-secondary-400));
    }
    .border-primary-400 {
    border: 2px solid var(--secondary-color);
    }
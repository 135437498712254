/* Custom Rules and Components */

.author-image {
    border-radius: 50%;
}

/* Navigation */

.navigation-bar {
    background-color: var(--primary-color);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1em;
    color: var(--tertiary-color);
}

.navigation-logo{
    height: fit-content;
}

.navigation-bar-items a {
    color: var(--tertiary-color);
}

.navigation-bar-menu {
    display: flex;
    gap: 1em;
    font-size: 20px;
}
.navigation-bar-menu li:hover {
    background-color: var(--secondary-color);
    border-radius: .4em;
    transition: 0.4s ease;
}
.navigation-bar-menu li {
    padding: .5em 1em;
}

.navigation-bar-shop-services {
    position: relative; 
}
.services-dropdown {
    background-color: var(--primary-color);
    padding: 1em 0.5em;
    position: absolute; 
    display: none;
    top: 2.8em;
    border: 1px solid var(--secondary-color);
}
.services-dropdown li + li {
    margin-top: 10px;
}
.services-dropdown li {
    padding: 0.5em 1em;
    width: 8em;
    text-align: center;
}
.services-dropdown li:hover {
    background-color: var(--secondary-color);
}
.navigation-bar-shop-services:hover .services-dropdown {
    display: block;
    z-index: 7;
}

/*Responsive navbar*/
input[type=checkbox]{
    display: none;
} 
.navigation-collapse {
    display: none;
    font-size: 1.5em;
    user-select: none;
}

/* Items */
.card {
    padding: .8em;
    background: var(--secondary-color);
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    margin: .2em;
    height: auto;
}

.card-info {
    padding-top: 10%;
}

svg {
    width: 1.2em;
    height: 1.2em;
}

.card-footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
    border-top: 1px solid #ddd;
}

/*Text*/
.text-title {
    font-size: 1.7em;
    line-height: 2;
}

.text-body {
    line-height: 1.5;
    font-size: 1em;
    padding-bottom: 10px;
}

/*Button*/
.card-button {
    border: 1px solid #252525;
    display: flex;
    padding: .3em;
    cursor: pointer;
    border-radius: 50px;
    transition: .3s ease-in-out;
}

.card-button:hover {
    border: 1px solid var(--tertiary-color);
    background-color: var(--tertiary-color);
}

/* inputs */
.input,
.button,
.options {
    max-width: 35em;
    height: 2.5em;
    background-color: #05060f0a;
    border-radius: .5rem;
    padding: 0 1rem;
    border: 2px solid transparent;
    font-size: 1rem;
    transition: border-color .3s cubic-bezier(.25,.01,.25,1) 0s, color .3s cubic-bezier(.25,.01,.25,1) 0s,background .2s cubic-bezier(.25,.01,.25,1) 0s;
}

.button {
    background-color: var(--tertiary-color);
    border: 1px solid var(--primary-color);
    margin: .3em;
}

.buttons-search {
    display: flex;
    align-items: center;
    justify-content: center;
}

.button:hover {
    background-color: var(--primary-color);
    color: var(--tertiary-color);
}

.label {
    display: block;
    margin-bottom: .3rem;
    font-size: .9rem;
    font-weight: bold;
    color: #05060f99;
    transition: color .3s cubic-bezier(.25,.01,.25,1) 0s;
}

.input:hover, .input:focus, .input-group:hover .input {
    outline: none;
    border-color: #05060f;
}

.input-group:hover .label, .input:focus {
    color: #05060fc2;
}

/* Cart */

.cart-dropdown {
    width: 100vw;
    height: 100vh;
    text-align: center;
    background-color: var(--secondary-color);
}

.cart-container {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    padding: 1.5em;
    border: 1px solid black;
}

.cart-item-header {
    text-align: center;
}

.cart-item-body {
    padding: 1.5em;
    display: flex;
    gap: .4em;
}

.cart-item-qt {
    display: flex;
    gap: .3em;
    height: 2em;
    border: 1px solid black;
}

.cart-dropdown > .cart-total {
    float: right;
}

.empty-message {
    font-size: 18px;
    margin: 50px auto;
}

/* Shop */


.shop-link:nth-child(1){
    grid-area: one;
    display: flex;
    justify-content: center;
    text-align: center;
}

.shop-link:nth-child(2){
    grid-area: two;
    display: flex;
    justify-content: center;
    text-align: center;
}

.shop-link:nth-child(3){
    grid-area: three;
    display: flex;
    justify-content: center;
    text-align: center;
}

.shop-link:nth-child(4){
    grid-area: four;
    display: flex;
    justify-content: center;
    text-align: center;
}

.shop-image {
    opacity: .7;
    border: 2px solid black;
    box-shadow: 0.2em .2em .1em black;
}

/* Originals Gallery */
.original-gallery {
    text-align: center;
    padding: 2em;
}

.original-gallery-items .singleitems {
    display: grid;
    gap: 1em;
    padding: 1.5em;
}

.title {
    position: absolute;
    transform: translate(0, 450%);
}

/* Contact card */
.contact-info {
    padding: 2em;
    text-align: center;
}

.contact-info-header {
    background-color: var(--secondary-color);
    padding-top: 2em;
    padding-bottom: 3em;
}

.contact-form {
    background-color: var(--secondary-color);
    padding: 1.5em;
}

/* Shopping cart items */
.items-sale-list {
    text-align: center;
    display: grid;
    gap: 2em;
}

.shopping-singleprice-cart {
    color: var(--secondary-color);
    text-align: center;
    border-radius: .5em;
    border: 1px solid black;
}

.shopping-singleprice-cart img {
    border-bottom: 1px solid black;
}

.singleitem-desc {
    padding: 1em;
}

.quantity-box-minus,
.quantity-box-plus {
    background-color: lightgray;
}

/* Footer */
.footer-container {
    background-color: var(--primary-color);
    color: var(--tertiary-color);
    display: grid;
    grid-template-columns: repeat(1,1fr);
    padding: 2em;
    text-align: center;
} 

.footer-social-gallery,
.footer-contact-gallery  {
    padding: 1em;
}

.footer-social-media-links,
.footer-contact-links {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding-top: 2em;
}

.footer-contact-links a,
.footer-social-media-links a {
    color: var(--tertiary-color);
    height: 3em;
}

.special-left h2 {
    font-size: 3em;
}

.special-left h3 {
    margin-top: -.5em;
    font-style: italic;
    font-size: 2em;
    color: rgb(169, 34, 34);
}

.special-left .limited {
    font-weight: 1000;
    font-size: 1.25em;
}

.special-right {
    display: flex;
    justify-content: center;
    align-items: center;
}

.special-right-h2 {
    text-align: center;
    font-size: 3em;
}

.special-right img {
    width: 65%;
}

@media (width >= 150em) {
    .original-gallery-items,
    .singleitems,
    .items-sale-list {
        grid-template-columns: repeat(4,1fr);
        padding: 1em;
    }

    .special-right img {
        width: 50%;
    }
}


@media (width >= 80em) and (width <= 150em) {
    .footer-container {
        grid-template-columns: repeat(2,1fr);
    } 

    .original-gallery-items,
    .singleitems,
    .items-sale-list {
        grid-template-columns: repeat(3,1fr);
        padding: 1em;
    }
}

@media (width >= 40em) and (width <= 80em) {
    .navigation-bar-menu { 
        font-size: 18px;
    }

    .original-gallery-items,
    .singleitems,
    .items-sale-list {
        grid-template-columns: repeat(2,1fr);
        padding: 1em;
    }
}

@media (width >= 40em) {

    .cart-dropdown {
        position: absolute;
        width: 35em;
        height: 25em;
        display: flex;
        flex-direction: column;
        padding: 1em;
        border: 1px solid black;
        background-color: white;
        top: 6em;
        right: 2em;
        z-index: 5;
        overflow: scroll;
    }

    .card-img {
        display: block;
        width: 250px;
        max-width: 100%;
        height: auto;
        margin: 0px auto 0px auto;
    }
    
}

@media (width <= 65em) {
    /* Navigation bar */
    .navigation-bar-menu { 
        display:none;
        position: absolute;
        background-color:var(--primary-color);
        right: 0;
        left: 0;
        text-align: center;
        padding: 1em 0;
        margin-top: 3em;
        z-index: 9;
        font-size: 14px;
    }
    .navigation-bar-menu li + li {
        margin-top: .6em;
    }
    input[type=checkbox]:checked ~ .navigation-bar-menu{
        display: block;
    }
    .navigation-collapse {
        display: block;
    }
    .services-dropdown {
        left: 50%;
        top: 1em;
        transform: translateX(35%);
    }

    .original-gallery {
        padding: 1em;
    }

    .card-img {
        display: flex;
        justify-content: center;
        object-fit: scale-down;
    }
    .special-left h2 {
        font-size: 1.75em;
    }
    
    .special-left h3 {
        font-size: 1.25em;
    }

    .special-left .limited {
        font-size: .9em;
    }
    
    .special-right-h2 {
        font-size: 1.5em;
    }
    
}